<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">

                    <div style="text-align:center">
                        <div class="container col-md-10 d-grid gap-2">
                            
                            <div class="form-group">
                                <input v-model="employee.user.username" class="form-control" type="text" placeholder="Korisničko ime:" aria-label="default input example" minlength="3" required>
                                <div class="invalid-feedback">
                                    Korisničko ime mora sadržati najmanje 3 karaktera i mora biti jedinstveno.
                                </div>
                            </div>

                            <div v-if="mode === 'ADD'" class="form-group">
                                <input v-model="employee.user.password" class="form-control" type="password" placeholder="Lozinka:" aria-label="default input example" minlength="6" required>
                                <div class="invalid-feedback">
                                    Lozinka mora sadržati najmanje 6 karaktera.
                                </div>
                            </div> 

                            <hr>

                            <div class="form-floating">
                                <input v-model="employee.user.first_name" class="form-control" type="text" placeholder="Ime:" aria-label="default input example" minlength="2" required>
                                <label for="floatingInput">Ime:</label>
                                <div class="invalid-feedback">
                                    Ime mora sadržati najmanje 2 karaktera.
                                </div>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.user.last_name" class="form-control" type="text" placeholder="Prezime:" aria-label="default input example" minlength="2" required>
                                <label for="floatingInput">Prezime:</label>
                                <div class="invalid-feedback">
                                    Prezime mora sadržati najmanje 2 karaktera.
                                </div>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.user.email" class="form-control" type="text" placeholder="Email:" aria-label="default input example" pattern="^[^@]+@[^@]+\.[^@]+$">
                                <label for="floatingInput">Email:</label>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.jmbg" class="form-control" type="text" placeholder="Jmbg:" aria-label="default input example">
                                <label for="floatingInput">Jmbg:</label>
                                <div class="invalid-feedback">
                                    Jmbg je obavezno polje.
                                </div>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.birthdate" class="form-control" type="text" onfocus="(this.type='date')" placeholder="Datum rođenja:" aria-label="default input example">
                                <label for="floatingInput">Datum rođenja</label>
                                <div class="invalid-feedback">
                                    Datum rođenja je obavezno polje.
                                </div>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.birthplace" class="form-control" type="text" placeholder="Mesto rođenja:" aria-label="default input example">
                                <label for="floatingInput">Mesto rođenja:</label>
                                <div class="invalid-feedback">
                                    Mesto rođenja je obavezno polje.
                                </div>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.phone" class="form-control" type="text" placeholder="Broj telefona:" aria-label="default input example" pattern="\+?[0-9]*" minlength="6">
                                <label for="floatingInput">Broj telefona:</label>
                                <div class="invalid-feedback">
                                    Broj telefona je obavezno polje i mora biti unet u ispravnom formatu.
                                </div>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.education" class="form-control" type="text" placeholder="Zanimanje:" aria-label="default input example">
                                <label for="floatingInput">Zanimanje:</label>
                            </div>

                            <div class="d-flex">
                                <div class="w-100">
                                    <div class="form-floating">
                                        <input v-model="employee.work_start_date" class="form-control" @change="emptyToUndefined(employee, 'work_start_date')" type="text" onfocus="(this.type='date')" placeholder="Početak rada:" aria-label="default input example">
                                        <label for="floatingInput">Početak rada</label>
                                    </div>
                                </div>
                                <div class="p-2 flex-shrink-1">
                                    <button type="button" class="btn-close" @click="clearData(employee, 'work_start_date')" aria-label="Close"></button>
                                </div>
                            </div>



                            <div class="d-flex">
                                <div class="w-100">
                                    <div class="form-floating">
                                        <input v-model="employee.application_start_date" class="form-control" @change="emptyToUndefined(employee, 'application_start_date')" type="text" onfocus="(this.type='date')" placeholder="Datum prijave:" aria-label="default input example">
                                        <label for="floatingInput">Datum prijave</label>
                                    </div>
                                </div>
                                <div class="p-2 flex-shrink-1">
                                    <button type="button" class="btn-close" @click="clearData(employee, 'application_start_date')" aria-label="Close"></button>
                                </div>
                            </div>


                            <div class="d-flex">
                                <div class="w-100">
                                    <div class="form-floating">
                                        <input v-model="employee.application_end_date" class="form-control" @change="emptyToUndefined(employee, 'application_end_date')" type="text" onfocus="(this.type='date')" placeholder="Datum odjave:" aria-label="default input example">
                                        <label for="floatingInput">Datum isteka prijave</label>
                                    </div>
                                </div>
                                <div class="p-2 flex-shrink-1">
                                    <button type="button" class="btn-close" @click="clearData(employee, 'application_end_date')" aria-label="Close"></button>
                                </div>
                            </div>



                            <div class="d-flex">
                                <div class="w-100">
                                    <div class="form-floating">
                                        <input v-model="employee.work_end_date" class="form-control" @change="emptyToUndefined(employee, 'work_end_date')" type="text" onfocus="(this.type='date')" placeholder="Kraj rada:" aria-label="default input example">
                                        <label for="floatingInput">Kraj rada</label>
                                    </div>
                                </div>
                                <div class="p-2 flex-shrink-1">
                                    <button type="button" class="btn-close" @click="clearData(employee, 'work_end_date')" aria-label="Close"></button>
                                </div>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.medical_examination_date" class="form-control" type="text" onfocus="(this.type='date')" placeholder="Lekarski pregled:" aria-label="default input example">
                                <label for="floatingInput">Datum lekarskog pregleda</label>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.health_card_expiration_date" class="form-control" type="text" onfocus="(this.type='date')" placeholder="Zdravstvena važi do:" aria-label="default input example">
                                <label for="floatingInput">Datum isteka zdravstvene knjižice</label>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.religious_holiday_date" class="form-control" type="text" onfocus="(this.type='date')" placeholder="Verski praznik:" aria-label="default input example">
                                <label for="floatingInput">Datum verskog praznika</label>
                            </div>

                            <div class="form-floating">
                                <input v-model="employee.shoe_size" class="form-control" type="number" placeholder="Broj cipela:" aria-label="default input example">
                                <label for="floatingInput">Broj cipela</label>
                            </div>

                            <select v-model="employee.transport_type" class="form-select" aria-label="Default select example">
                                <option disabled :value="undefined">Način prevoza</option>
                                <option value="OWN">Sopstveni</option>
                                <option value="BUS">Autobus</option>
                                <option value="COMPANY">Firmin</option>
                            </select>

                            <select v-model="employee.user.role" class="form-select" aria-label="Default select example" required>
                                <option disabled :value="undefined">Uloga</option>
                                <option value="ADMIN">Admin</option>
                                <option value="MANAGER">Menadžer</option>
                                <option value="WORKER">Radnik</option>
                            </select>
                            <div class="invalid-feedback">
                                Uloga obavezno polje.
                            </div>



                            <!-- <div class="form-floating">
                                <input v-model="employee.user.avatar_url" class="form-control" type="text" placeholder="URL Slike:" aria-label="default input example">
                                <label for="floatingInput">URL Slike:</label>
                            </div> -->

                            <img class="upload-preview" v-if="employee.user.avatar_url" :src="employee.user.avatar_url" alt="">

                            <p v-if="uploading">Dodaje se</p>
                            <div class="form-floating">
                                <file-upload
                                    class="form-control"
                                    post-action="/upload/post"
                                    extensions="gif,jpg,jpeg,png,webp"
                                    accept="image/png,image/gif,image/jpeg,image/webp"
                                    :multiple="false"
                                    :size="1024 * 1024 * 10"
                                    v-model="file"
                                    @input-filter="inputFilter"
                                    @input-file="inputFile"
                                    ref="upload">
                                    <i class="fa fa-plus"></i>
                                    Promeni sliku
                                </file-upload>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="$emit('confirmed')" type="button" class="btn btn-primary"> Potvrdi </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AwsService from '@/service/AwsService.js'
export default {

    props: {
        modalId: String,
        title: String,
        mode: String,
        employee: Object
    },

    data() {
        return {
            upload: null,
        
            file: [],
            uploading: false,
        }
    },

    methods: {
        clearData(obj, atr){
            obj[atr] = undefined;
        },

        emptyToUndefined(obj, atr) {
            if (obj[atr] == "")
                obj[atr] = undefined;
        },

        inputFilter(newFile, oldFile, prevent) {
            console.log("FILTER");
            if (newFile && !oldFile) {
                if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                return prevent()
                }
                
                if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                return prevent()
                }
            }
        },

        async inputFile(newFile, oldFile) {
            if (newFile && !oldFile) {
                console.log('add', newFile)
                this.uploadImage();
            }
            if (newFile && oldFile) {
                console.log('update', newFile)
                this.uploadImage();
            }
            if (!newFile && oldFile) {
                // remove
                console.log('remove', oldFile)
            }
        },

        async uploadImage() {
            this.uploading = true;
            console.log(this.file);
            let imageUrl = await AwsService.uploadFile(this.file[0].file).catch(error => {
                alert("Neuspesno dodavanje")
                console.log(error);
            });
            this.uploading = false;
            this.employee.user.avatar_url = imageUrl;

        },

    }

}
</script>

<style>
p {
    font-size: 140%;
}

.upload-preview {
    height: 100px;
    text-align: center;
}
</style>