<template>
    <div id="app">
      <router-view/>
    </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css?family=Varela+Round');
@import '../node_modules/@syncfusion/ej2-base/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../node_modules/@syncfusion/ej2-treegrid/styles/material.css";
@import '../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';
@import "../node_modules/@syncfusion/ej2-pivotview/styles/material.css";


@import './assets/main-style.css';
@import "../node_modules/ol/src/ol.css";


#app {
    font-family: Varela Round, Avenir, Helvetica, Arial, sans-serif;
    font-size: 0.8em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

</style>

<script>
    import $ from 'jquery';
    import axios from 'axios';

    import {mapState} from 'vuex'

    export default {

        methods: {
            logout() {
                
                this.$store.dispatch('setLoggedInUser', null);
                this.$store.dispatch('resetVuex');
                localStorage.removeItem('user');
                this.$router.push({name: 'Login'});
            },

            getAssignmentRequestData() {
                return {
                    employee_id: this.user.id,
                    params: {
                        current: true,
                    }
                };
            }
        },


        computed: {
            ...mapState([
                'user',
            ]),
        },

        beforeMount() {
            if (location.protocol !== 'https:' && (location.host.includes('heroku') || location.host.includes('mkel.rs'))) {
                location.replace(`https:${location.href.substring(location.protocol.length)}`);
            }

            if (!localStorage.getItem('user'))
                return;
            
            let _this = this;
            let user = JSON.parse(localStorage.getItem('user'));
            this.$store.dispatch('setLoggedInUser', user);
            console.log(user);

            let token = user.access;
            
            axios.defaults.headers.common['Authorization'] = "Bearer " + token; // for all requests
            axios.interceptors.response.use(function (response) {
                return response;
            }, function (error) {
                if (error?.response?.status === 401) {
                
                    _this.logout();
                }
                return Promise.reject(error);
            });

        },

        async created() {
            await this.$store.dispatch('loadAuthorizedUser');
            this.$store.dispatch('loadUserAssignment', this.getAssignmentRequestData());
            this.$store.dispatch('loadUserSettings');
            this.$store.dispatch('loadSettings');

            
        },
        
        updated() {
            $('.modal-backdrop').remove();
            $('body').removeClass('modal-open');
        }
    }
</script>
