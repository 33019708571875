import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class EmployeesService {

    getAllEmployees() {
        return axios.get(`${API_URL}/employees`);
    }

    getDeletedAllEmployees() {
        return axios.get(`${API_URL}/employees/all`);
    }

    getActiveEmployees() {
        return axios.get(`${API_URL}/employees/active`);
    }

    getAllActiveUsers() {
        return axios.get(`${API_URL}/employees/active/users`);
    }

    getOneEmploee(id) {
        return axios.get(`${API_URL}/employees/` + id);
    }
    
    getOneEmploeeByUsername(username) {
        return axios.get(`${API_URL}/employees/username/` + username);
    }

    getJobsForEmployee(id) {
        return axios.get(`${API_URL}/usemployeesers/` + id + '/jobs');
    }

    getPreWorkOrdersForEmployee(data) {
        return axios.get(`${API_URL}/employees/` + data.id + `/pre-work-orders`, { params: {'date': data.date, 'last_date': data.last_date, 'current': data.current } });
    }

    getAllWorkers() {
        return axios.get(`${API_URL}/employees/workers`);
    }
    
    getAllManagers() {
        return axios.get(`${API_URL}/employees/managers`);
    }
    
    getWorkOrderWorkers(workOrderId) {
        return axios.get(`${API_URL}/work-orders/` + workOrderId + `/workers`);
    }
    
    getAvailableWorkersInWorkOrder(data) {
        return axios.get(`${API_URL}/work-orders/` + data.work_order_id + `/available-workers`, {params: { 'datetime': data.datetime, 'date': data.date }} );
    }

    addEmployee(employeeData) {
        return axios({
            method: 'POST',
            url: `${API_URL}/employees`,
            data: employeeData
        })
    }

    updateEmployee(employeeData) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/employees/` + employeeData.id,
            data: employeeData
        })
    }

    deleteEmployee(employeeData) {
        return axios({
            method: 'DELETE',
            url: `${API_URL}/employees/` + employeeData.user.id,
        })
    }

    changeWorkOrder(id, workOrderId) {
        return axios({
            method: 'PUT',
            url: `${API_URL}/employees/` + id + '/current-work-order?workOrderId=' + workOrderId
        })
    }

    updatePassword(id, data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/employees/` + id + '/update-password',
            data: data
        })
    }

    addPreWorkOrderEmployee(id, data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/employees/` + id + '/pre-work-orders',
            data: data
        })
    }

    getPreWorkOrderEmployeesTimeLine(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/pre-work-orders`,
            params: data
        })
    }

    getEmployeesCarnet(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/carnet`,
            params: data
        })
    }

    getEmployeesCarnetDays(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/carnet/days`,
            params: data
        })
    }

    getEmployeesCarnetMonths(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/carnet/months`,
            params: data
        })
    }

    getEmployeeCarnetDays(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + data.employee_id + `/carnet/days`,
            params: data
        })
    }

    getEmployeeCarnetMonths(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + data.employee_id + `/carnet/months`,
            params: data
        })
    }

    getWorkStartDateHistory(id) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + id + '/history-work-start-date',
        })
    }

    getApplicationStartDateHistory(id) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + id + '/history-application-start-date',
        })
    }

    getApplicationEndDateHistory(id) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + id + '/history-application-end-date',
        })
    }

    getWorkEndDateHistory(id) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + id + '/history-work-end-date',
        })
    }

    getMedicalExaminationDateHistory(id) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + id + '/history-medical-examination-date',
        })
    }

    getHealthCardExpirationDateHistory(id) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + id + '/history-health-card-expiration-date',
        })
    }

    getReligiousHolidayHistory(id) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + id + '/history-religious-holiday',
        })
    }

    getWorkAttendance(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + data.employee_id + `/work-attendance`,
            params: {'date': data.date}
        })
    }

    postWorkAttendance(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/employees/` + data.employee_id + `/work-attendance`,
            data: data,
            params: {'date': data.date}
        })
    }

    timesheetLeave(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/work-orders/work-attendance/timesheets/` + data.id + `/leave`,
            data: data,
        })
    }

    deleteWorkAttendance(data) {
        return axios({
            method: 'DELETE',
            url: `${API_URL}/employees/` + data.employee_id + `/work-attendance`,
            data: data
        })
    }

    getAbsences(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/` + data.employee_id + `/absence`,
        })
    }

    postAbsence(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/employees/` + data.employee_id + `/absence`,
            data: data,
        })
    }

    postAbsenceRequest(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/absences/request`,
            data: data,
        })
    }

    deleteAbsence(data) {
        return axios({
            method: 'DELETE',
            url: `${API_URL}/employees/` + data.employee_id + `/absence`,
            data: data
        })
    }

    getSelfWorkAttendace(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/work-attendance/self-sign`,
            data: data,
        })
    }

    selfWorkAttendace(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/work-attendance/self-sign`,
            data: data,
        })
    }


    selfWorkAttendaceStart(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/work-attendance/self-sign-start`,
            data: data,
        })
    }


    selfWorkAttendaceEnd(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/work-attendance/self-sign-end`,
            data: data,
        })
    }

    getEmployeeTimeline(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/${data.employee_id}/timeline`,
            params: data.params,
        })
    }

    getEmployeesPWOat(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/employees/pre-work-orders/day`,
            params: data,
        })
    }

}

export default new EmployeesService();